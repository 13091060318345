import Vue from 'vue'
import i18n from '../i18n'
import axios from 'axios'
import {BToast} from 'bootstrap-vue'
import store from '../store'

Vue.component('b-toast', BToast)

const helper = {
    successSound: new Audio(require('@/assets/sounds/success.mp3')),
    errorSound: new Audio(require('@/assets/sounds/error.mp3')),
    notificationSound: new Audio(require('@/assets/sounds/notification.mp3')),
    newMailSound: new Audio(require('@/assets/sounds/newmail.mp3')),
    playMailSound() {
        try {
            this.newMailSound.play()
        } catch (e) {
        }
    },
    getCurrency() {
        return store.state.settings.currency
    },
    isPassportScanAllowed() {
        return store.state.settings.scanPassport || false
    },
    getAccountCountry() {
        return store?.state?.settings?.accountCountry
    },
    showGuestFullDetails() {
        const country = this.getAccountCountry()
        return (country === 'IT' || country === 'CH' || country === 'HR')
    },
    showTaxReceiverCode() {
        const country = this.getAccountCountry()
        return (country === 'IT')
    },
    showGuestProvinceCode() {
        const country = this.getAccountCountry()
        return (country === 'IT')
    },
    getCurrencyLanguage() {
        return i18n.locale
    },
    euFormatter() {
        return new Intl.NumberFormat(this.getCurrencyLanguage(), {style: 'currency', currency: this.getCurrency()})
    },
    getCurrencySymbol() {
        const amt = this.euFormatter().format(0)
        return amt.replace(/[\d., ]/g, '')
    },
    getDecimalFormatter() {
        return new Intl.NumberFormat(this.getCurrencyLanguage(), {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: this.getCurrency()
        })
    },
    convertToCurrency(number, fractions = 2) {
        if (fractions === 2) {
            return i18n.n(number, 'currency')
        } else {
            return i18n.n(number, 'currencyNoCents')
        }
    },
    convertToEu(number) {
        const eu = this.getDecimalFormatter()
        return eu.format(number).replace(/^(-)|[^0-9.,]+/g, '$1')
    },
    convertToEuWithoutSeperator(number) {
        const currency = this.getCurrency()
        const formatter = new Intl.NumberFormat(this.getCurrencyLanguage(), {
            style: 'decimal',
            currency: currency,
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return formatter.format(number)
    },
    toNoDecimalNumber(number) {
        const formatter = new Intl.NumberFormat(this.getCurrencyLanguage(), {
            style: 'decimal',
            useGrouping: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
        return formatter.format(number)
    },
    convertToEuWithOutDecimal(number) {
        const eu = this.getDecimalFormatter()
        return eu.format(number).replace(/^(-)|[^0-9.,]+/g, '$1')
    },
    convertIntoUS(value) {
        const number = value.toString()
        if (number.includes(',') && number.includes('.')) { // comma and dot exists
            const commaIndex = number.indexOf(',')
            const dotIndex = number.indexOf('.')
            if (commaIndex < dotIndex) { // Thousand separator , Decimal Separator .
                const num = number.replace(',', '')
                return (isNaN(num)) ? 0 : num
            } else { // Thousand separator . Decimal Separator ,
                let num = number.replace('.', '')
                num = num.replace(',', '.')
                return (isNaN(num)) ? 0 : num
            }
        } else if (number.includes(',')) { // comma exists
            const num = number.replace(',', '.')
            return (isNaN(num)) ? 0 : num
        } else {
            return (isNaN(number)) ? 0 : number
        }
    },
    getVariationTypeSymbol(type) {
        if (type === '1') {
            return '%'
        } else {
            return this.getCurrencySymbol()
        }
    },
    setLogin(data) {
        localStorage.setItem('HosAdminAuthToken', data.token)
        localStorage.setItem('HosAdminData', JSON.stringify(data.user))
    },
    isLoggedIn() {
        const token = localStorage.getItem('HosAdminAuthToken')
        return !(token === null || token === '');
    },
    getToken() {
        const token = localStorage.getItem('HosAdminAuthToken')
        if (token === null || token === '') {
            return ''
        } else {
            return token
        }
    },
    isMainAdmin() {
        const adminData = JSON.parse(localStorage.getItem('HosAdminData'))
        return (adminData.isMainAdmin === undefined) ? false : adminData.isMainAdmin
    },
    canUserRefund() {
        const adminData = JSON.parse(localStorage.getItem('HosAdminData'))
        return (adminData.canRefund === undefined) ? false : adminData.canRefund
    },
    isSuperAdmin() {
        const adminData = JSON.parse(localStorage.getItem('HosAdminData'))
        return (adminData.isMainAdmin === undefined) ? false : adminData.isSnowofficeUser
    },
    setLogOut() {
        localStorage.setItem('HosAdminAuthToken', '')
    },
    languages() {
        return [
            {value: 'en', text: 'English'},
            {value: 'de', text: 'Deutsch'},
            {value: 'es', text: 'Español'},
            {value: 'hr', text: 'Hrvatski'},
            {value: 'it', text: 'Italiano'},
            {value: 'fr', text: 'Français'}
        ]
    },
    showSuccess(message) {
        try {
            this.successSound.play()
        } catch (e) {
        }
        Vue.swal({
            icon: 'success',
            title: message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
                container: 'swal-width'
            },
            keydownListenerCapture: true,
            heightAuto: false,
            timerProgressBar: true,
            didOpen: (toast) => {
                // toast.addEventListener('mouseenter', Vue.swal.stopTimer)
                // toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
            }
        })
    },
    playErrorSound() {
        try {
            this.errorSound.play()
        } catch (e) {
        }
    },
    playNotification() {
        try {
            this.notificationSound.play()
        } catch (e) {
        }
    },
    showError(message) {
        try {
            this.errorSound.play()
        } catch (e) {
        }
        this.hideLoader()
        Vue.swal({
            icon: 'warning',
            title: i18n.t('error'),
            text: message,
            showConfirmButton: false,
            keydownListenerCapture: false,
            heightAuto: false,
            showCancelButton: true,
            cancelButtonText: i18n.t('understand'),
            customClass: {
                popup: 'error-swal'
            }
        })
    },
    showInfo(message) {
        try {
            this.errorSound.play()
        } catch (e) {
        }
        this.hideLoader()
        Vue.swal({
            icon: 'info',
            title: i18n.t('information'),
            text: message,
            showConfirmButton: true,
            keydownListenerCapture: false,
            heightAuto: false,
            showCancelButton: false,
            confirmButtonText: i18n.t('understand')
        })
    },
    showLoader(message = '') {
        const text = (message === '' || message === undefined) ? 'Processing' : message
        Vue.swal.fire({
            title: '',
            text: text,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            heightAuto: false,
            width: '16rem',
            willOpen: () => {
                Vue.swal.showLoading()
            },
            showClass: {
                popup: 'swalBlurBody'
            }
        })
    },
    hideLoader() {
        Vue.swal.close()
    },
    rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.status === '1') {
            return 'green lighten-5'
        } else {
            return 'red lighten-5'
        }
    },
    taxes() {
        const taxes = []
        for (let i = 0; i <= 30; i++) {
            taxes.push({
                value: `${i}`, text: `${i} %`
            })
        }
        return taxes
    },
    customMailTypes(mailFor) {
        if (mailFor === 'INVOICE') {
            return [
                {value: 'NONE', text: i18n.t('none')},
                {value: 'NEW_INVOICE', text: i18n.t('newInvoice')},
                {value: 'NEW_INVOICE_OFFER', text: i18n.t('offer')},
                {value: 'INVOICE_ARCHIVED', text: i18n.t('archived')}
            ]
        } else {
            return [
                {value: 'NONE', text: i18n.t('none')},
                {value: 'NEW_RESERVATION', text: i18n.t('newReservation')},
                {value: 'NEW_GROUP_RESERVATION', text: i18n.t('newGroupReservation')},
                {value: 'OPTIONAL_RESERVATION', text: i18n.t('optionalReservation')},
                {value: 'RESERVATION_MODIFIED', text: i18n.t('reservationModification')},
                {value: 'RESERVATION_CANCELLED', text: i18n.t('reservationCancellation')},
                {value: 'SEND_BEFORE_ARRIVAL', text: i18n.t('sendDayBeforeArrival')}, // sevenDayBeforeArrival
                {value: 'AFTER_CHECK_OUT', text: i18n.t('afterCheckOut')},
                {value: 'ROOM_READY', text: i18n.t('roomReady')},
                {value: 'CHECK_IN_INFO', text: i18n.t('checkInInfoMail')},
                {value: 'AFTER_CHECK_IN', text: i18n.t('afterCheckIn')},
                // { value: 'BOOKING_CONFIRMED', text: i18n.t('bookingConfirmed') }
            ]
        }
    },
    getMailCustomParams() {
        return {
            commonParams: [
                '{customerCompany}', '{firstName}', '{lastName}', '{companyName}', '{companyIBAN}', '{companyUID}', '{companyBIC}',
                '{contactNumber}', '{infoMail}', '{companyStreet}', '{companyPostalCode}', '{companyCity}'
            ],
            normalParams: [
                '{link}', '{checkIn}', '{checkOut}', '{bookingNumber}', '{today}',
                '{bookingDate}', '{balance}', '{amount}', '{houseName}',
                '{10%}', '{20%}', '{25%}', '{50%}', '{80%}', '{100%}', '{offerEndDate}',
                '{checkInTime}', '{checkOutTime}' // '{qrCode}'
            ],
            houseParams: [
                '{houseStreet}', '{houseCity}', '{housePostalCode}', '{houseName}'
            ],
            groupParams: [
                '{groupName}', '{fromDate}', '{toDate}', '{expiryDate}', '{discount}',
                '{groupLink}'
            ],
            cancelParams: [
                '{reason}'
            ],
            roomReadyParam: [
                '{firstName}', '{lastName}',
                '{passCode}', '{roomNumber}', '{bookingNumber}', '{houseName}',
                '{link}', '{checkIn}', '{checkOut}', '{roomCategory}', '{roomNumber}'
            ],
            checkInInfoParam:
                [
                    '{roomsAndPasscode}'
                ],
            invoiceParams:
                [
                    '{invoiceDate}', '{invoiceNumber}', '{offerNumber}', '{offerDate}', '{invoiceAmount}'
                ]
        }
    },
    downloadZip(url, params, fileName = 'HosBooking.zip') {
        this.showLoader()
        axios.get(url, {
            responseType: 'blob',
            params: params
        }).then(response => {
            this.hideLoader()
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName) // change the file name if needed
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }).catch((error) => {
            this.showError(error.toString())
        })
    },
    printUrl(url, params, type = 'application/pdf') {
        this.showLoader()
        axios.get(url, {
            responseType: (type === 'application/pdf') ? 'arraybuffer' : 'blob',
            params: params
        }).then(response => {
            this.hideLoader()
            // x-file-name
            let blob
            if (type === 'application/pdf') {
                blob = new Blob([response.data], {type: type})
            } else if (type === 'text/csv') {
                blob = new Blob([response.data], {type: 'text/csv'})
            } else {
                blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
            }
            const url = window.URL.createObjectURL(blob)
            const newWin = window.open(url, 'hosbooking')
            if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                Vue.swal({
                    icon: 'error',
                    title: i18n.t('popupBlocked'),
                    text: i18n.t('unBlockPopupInfo'),
                    showConfirmButton: true,
                    iconColor: 'red',
                    background: '#fce4ec'
                })
            }
        }).catch((error) => {
            this.showError(error.toString())
        })
    },
    downloadFile({url, params, fileName}) {
        try {
            this.showLoader()
            axios.get(url, {
                params,
                responseType: 'blob'
            }).then(response => {
                try {
                    if (response.headers['x-file-name'] !== undefined) {
                        fileName = response.headers['x-file-name'];
                    }
                    this.hideLoader()
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                } catch (error) {
                    this.showError(error.message)
                }
            }).catch(error => {
                this.showError(error.message)
            })
        } catch (error) {
            this.showError(error.message)
        }
    },
    async printInvoice(reservationCode) {
        const {value: accept} = await Vue.swal({
            icon: 'question',
            title: i18n.t('printInvoice'),
            showDenyButton: false,
            showCancelButton: true,
            cancelButtonText: i18n.t('travelAgency'),
            confirmButtonText: i18n.t('fullInvoice'),
            // denyButtonText: i18n.t('splitInvoice')
            // footer: '<a href="https://www.snowoffice.at/" target="_blank">' + this.$t('whatIsSplitInvoice') + '</a>'
        }).then(result => {
            if (result.isConfirmed) {
                this.printUrl('content/printInvoice', {
                    id: reservationCode
                })
            } else if (result.isDenied) {
                this.printUrl('content/printSplitInvoice', {
                    id: reservationCode
                })
            } else if (result.isDismissed && result.dismiss === 'cancel') {
                this.printUrl('content/printInvoice', {
                    id: reservationCode,
                    type: 'travelAgency'
                })
            }
        })
    },
    getAllWeekDaysFromDay(days) {
        if (days === null) {
            return ''
        } else {
            const newArray = []
            days.forEach(value => {
                if (value.toString() === '1') {
                    newArray.push(i18n.t('week.mon'))
                } else if (value.toString() === '2') {
                    newArray.push(i18n.t('week.tue'))
                } else if (value.toString() === '3') {
                    newArray.push(i18n.t('week.wed'))
                } else if (value.toString() === '4') {
                    newArray.push(i18n.t('week.thu'))
                } else if (value.toString() === '5') {
                    newArray.push(i18n.t('week.fri'))
                } else if (value.toString() === '6') {
                    newArray.push(i18n.t('week.sat'))
                } else if (value.toString() === '7') {
                    newArray.push(i18n.t('week.sun'))
                }
            })
            return (newArray.length > 0) ? newArray.join(', ') : ''
        }
    },
    calcTax(brutto, taxPercentage) {
        const taxRate = 1 + (taxPercentage / 100)
        const netto = (brutto / taxRate)
        const taxAmount = (brutto - netto).toFixed(2)
        return {
            netto: netto.toFixed(2),
            taxAmount: taxAmount,
            brutto: brutto
        }
    },
    getDiscountData(amount, discountPercentage) {
        const price = parseFloat(amount)
        const percentage = parseFloat(discountPercentage)
        const discountAmount = price * (percentage / 100);
        const discountedAmount = price - discountAmount;
        return {
            discount: discountAmount,
            amount: discountedAmount
        }
    },
    isAmountValid(amount) {
        const pattern = /^-?\d+(?:[.,]\d{1,2})?$/
        if (amount === '') {
            return false
        } else {
            return pattern.test(amount)
        }
    },
    isDiscountValid(amount) {
        const pattern = /^(?:100(?:[.,]0{1,2})?|\d{1,2}(?:[.,]\d{1,2})?)$/
        if (amount === '') {
            return false
        } else {
            return pattern.test(amount)
        }
    },
    calcBruttoFromNetto(netto, taxPercentage) {
        const amount = parseFloat(netto)
        const taxRate = taxPercentage / 100
        const taxAmount = parseFloat((amount * taxRate).toFixed(2))
        const brutto = taxAmount + amount
        return {
            netto: netto,
            taxAmount: taxAmount,
            brutto: brutto
        }
    },
    bookingSource() {
        return store.state.sources
    },
    getMonthName(month) {
        if (month === 1) {
            return i18n.t('months.jan')
        } else if (month === 2) {
            return i18n.t('months.feb')
        } else if (month === 3) {
            return i18n.t('months.mar')
        } else if (month === 4) {
            return i18n.t('months.apr')
        } else if (month === 5) {
            return i18n.t('months.may')
        } else if (month === 6) {
            return i18n.t('months.jun')
        } else if (month === 7) {
            return i18n.t('months.jul')
        } else if (month === 8) {
            return i18n.t('months.aug')
        } else if (month === 9) {
            return i18n.t('months.sep')
        } else if (month === 10) {
            return i18n.t('months.oct')
        } else if (month === 11) {
            return i18n.t('months.nov')
        } else if (month === 12) {
            return i18n.t('months.dec')
        }
    },
    guestReportingProviders() {
        return [
            {value: '0', text: '-- None --'},
            {value: '1', text: 'Feratel Media AG - AT'},
            {value: '2', text: 'Comm-Unity'},
            {value: '3', text: 'LTS Südtirols'},
            {value: '4', text: 'Hotelkontrolle (HOKO)'},
            {value: '5', text: 'Cap Corn (Cape Ticket)'},
            {value: '6', text: 'Manual Book Keeping'},
        ]
        /* All data
        0 => Ohne Provider
        1 => Feratel Media AG - AT
        2 => Comm-Unity
        3 => SW-Tech
        4 => Intobis
        5 => Thaler
        6 => Neuhold
        7 => Feratel Media AG - CH
        8 => Tibidono
        9 => AVS
        10 => Wilken
        11 => cardXperts
        12 => capcorn
        13 => geios
        14 => Alturos
         */
    },
    guestReportingProvidersRegion() {
        return [
            {value: '', text: '-- NONE --'},
            {value: 'SAALBACH', text: 'Saalbach'},
            {value: 'HINTERGLEMM', text: 'Hinterglemm'},
            {value: 'ZELLAMSEE', text: 'Zell am See'},
            {value: 'KAPRUN', text: 'Kaprun'},
        ]
        /* All data
        0 => Ohne Provider
        1 => Feratel Media AG - AT
        2 => Comm-Unity
        3 => SW-Tech
        4 => Intobis
        5 => Thaler
        6 => Neuhold
        7 => Feratel Media AG - CH
        8 => Tibidono
        9 => AVS
        10 => Wilken
        11 => cardXperts
        12 => capcorn
        13 => geios
        14 => Alturos
         */
    },
    paymentOptions() {
        return [
            {value: '1', text: 'Cash', name: 'cash'},
            {value: '2', text: 'Paypal', name: 'paypal'},
            {value: '3', text: 'Sofort', name: 'sofort'},
            {value: '4', text: 'Credit Card', name: 'creditcard'},
            {value: '5', text: 'Transfer', name: 'transfer'},
            {value: '6', text: 'Online CC', name: 'online'},
            {value: '7', text: 'Voucher', name: 'voucher'}
            // { value: '8', text: 'VC Pre-Payment' }
        ]
    },
    paymentCards() {
        return [
            {value: 'AMEX', text: 'AMEX'},
            {value: 'VISA', text: 'VISA'},
            {value: 'VISA DEBIT', text: 'VISA DEBIT'},
            {value: 'VPAY', text: 'VPAY'},
            {value: 'DINERS', text: 'DINERS'},
            {value: 'DISCOVER', text: 'DISCOVER'},
            {value: 'JCB', text: 'JCB'},
            {value: 'MAESTRO', text: 'MAESTRO'},
            {value: 'MASTER', text: 'MASTER'},
            {value: 'MASTER DEBIT', text: 'MASTER DEBIT'},
            {value: 'UNION PAY', text: 'UNION PAY'}
        ]
    },
    convertIntoCreditCard(cardNumber, length = 4) {
        if (cardNumber !== null && cardNumber.length > 0) {
            const split = cardNumber.match(/.{1,4}/g)
            return split.join('-')
        } else {
            return ''
        }
    },
    fiscalPartners() {
        return [
            {value: '0', text: i18n.t('none')},
            // { value: '1', text: 'I-Cash Invoice' },
            {value: '2', text: 'Fiskal Trust (AT)'},
            // { value: '3', text: 'Ready 2 Order' },
            {value: '4', text: 'E-Factura (IT)'},
            {value: '5', text: 'Fiskal Trust (DE)'}
        ]
    },
    fiscalProvider(provider, settingsProvider) {
        if (provider === '-1') {
            if (settingsProvider === '0') {
                return i18n.t('none')
            } else if (settingsProvider === '1') {
                return 'I-Cash Invoice'
            } else if (settingsProvider === '2') {
                return 'Fiskal Trust (AT)'
            } else if (settingsProvider === '3') {
                return 'Ready2Order'
            } else if (settingsProvider === '4') {
                return 'E-Factura (IT)'
            } else if (settingsProvider === '5') {
                return 'Fiskal Trust (DE)'
            } else {
                return ''
            }
        } else if (provider === '0') {
            return i18n.t('none')
        } else if (provider === '1') {
            return 'I-Cash Invoice'
        } else if (provider === '2') {
            return 'Fiskal Trust (AT)'
        } else if (provider === '3') {
            return 'Ready2Order'
        } else if (provider === '4') {
            return 'E-Factura (IT)'
        } else if (provider === '5') {
            return 'Fiskal Trust (DE)'
        } else {
            return ''
        }
    },
    checkFeatureValue(featureValue) {
        const feature = typeof featureValue === 'number' ? Number(featureValue > 0 ? featureValue : 0).toString(16) : featureValue
        const featureLen = feature.length
        const feature1 = featureLen > 8 ? parseInt(feature.substr(feature.length - 8), 16).toString(2) : parseInt(feature, 16).toString(2)
        const feature2 = featureLen > 16 ? parseInt(feature.substr(feature.length - 16, 8), 16).toString(2) : parseInt(feature.substr(0, feature.length - 8), 16).toString(2)
        const feature3 = featureLen > 24 ? parseInt(feature.substr(feature.length - 24, 8), 16).toString(2) : parseInt(feature.substr(0, feature.length - 16), 16).toString(2)
        const feature4 = featureLen > 32 ? parseInt(feature.substr(feature.length - 32, 8), 16).toString(2) : parseInt(feature.substr(0, feature.length - 24), 16).toString(2)
        const value = ''.concat(
            isNaN(feature4) ? '' : (feature4.length >= 8 ? feature4 : `${new Array(8 - feature4.length).fill(0).join(' ')}${feature4}`),
            isNaN(feature3) ? '' : (feature3.length >= 8 ? feature3 : `${new Array(8 - feature3.length).fill(0).join(' ')}${feature3}`),
            isNaN(feature2) ? '' : (feature2.length >= 8 ? feature2 : `${new Array(8 - feature2.length).fill(0).join(' ')}${feature2}`),
            isNaN(feature1) ? '' : (feature1.length >= 8 ? feature1 : `${new Array(8 - feature1.length).fill(0).join(' ')}${feature1}`)
        )
        return this.changeEndianness(value)
    },
    changeEndianness(string) {
        const splitString = string.split('')
        const reverseArray = splitString.reverse()
        return reverseArray.join('')
    },
    getPersonTitle(title) {
        let out = ''
        if (title === '1') {
            out = i18n.t('mr')
        } else if (title === '2') {
            out = i18n.t('mrs')
        } else if (title === '3') {
            out = i18n.t('ms')
        } else {
            out = ''
        }
        return (out !== '') ? out : ''
    },
    pagiFrom(totalResults, currentPage, perPage) {
        return (totalResults > 0) ? ((currentPage - 1) * perPage) + 1 : 0
    },
    pagiTo(totalResults, currentPage, perPage) {
        return ((currentPage - 1) * perPage) + totalResults
    },
    sendMailLanguage() {
        return [
            {value: 'de', text: 'DE'},
            {value: 'en', text: 'EN'},
            {value: 'es', text: 'ES'},
            {value: 'fr', text: 'FR'},
            {value: 'it', text: 'IT'},
            {value: 'hr', text: 'HR'}
        ]
    },
    getMailBoxFolderIcon(path, mailBox = null) {
        const stdFolder = mailBox.standardFolders
        if (stdFolder == null || path === '') {
            return 'folder2'
        } else {
            if (path === stdFolder?.inbox) {
                return 'inbox'
            } else if (path === stdFolder?.sent) {
                return 'arrow-up-right-square'
            } else if (path === stdFolder?.junk) {
                return 'exclamation-circle'
            } else if (path === stdFolder?.trash) {
                return 'trash'
            } else if (path === stdFolder?.draft) {
                return 'pencil-square'
            } else {
                return 'folder2'
            }
        }
    },

    getPercentageAmount(amount, percentage) {
        return (parseFloat(percentage) / 100) * parseFloat(amount)
    },
    getItalyProvince(stateCode, country) {
        const state = stateCode.toString().toLowerCase()
        const outPut = []
        outPut.push({value: '', text: 'Select Province'})
        if (state === 'abruzzo') {
            outPut.push({value: 'CH', text: 'CH - Chieti'})
            outPut.push({value: 'AQ', text: 'AQ - L\'Aquila'})
            outPut.push({value: 'PE', text: 'PE - Pescara'})
            outPut.push({value: 'TE', text: 'TE - Teramo'})
        } else if (state === 'basilicata') {
            outPut.push({value: 'MT', text: 'MT - Matera'})
            outPut.push({value: 'PZ', text: 'PZ - Potenza'})
        } else if (state === 'bozen') {
            outPut.push({value: 'BZ', text: 'BZ - Bozen'})
        } else if (state === 'calabria') {
            outPut.push({value: 'CS', text: 'CS - Cosenza'})
            outPut.push({value: 'CZ', text: 'CZ - Catanzaro'})
            outPut.push({value: 'KR', text: 'KR - Crotone'})
            outPut.push({value: 'RC', text: 'RC - Reggio Calabria'})
            outPut.push({value: 'VV', text: 'VV - Vibo Valentia'})
        } else if (state === 'campania') {
            outPut.push({value: 'AV', text: 'AV - Avellino'})
            outPut.push({value: 'BN', text: 'BN - Benevento'})
            outPut.push({value: 'CE', text: 'CE - Caserta'})
            outPut.push({value: 'NA', text: 'NA - Napoli'})
            outPut.push({value: 'SA', text: 'SA - Salerno'})
        } else if (state === 'emilia-romagna') {
            outPut.push({value: 'BO', text: 'BO - Bologna'})
            outPut.push({value: 'FE', text: 'FE - Ferrara'})
            outPut.push({value: 'FC', text: 'FC - Forli'})
            outPut.push({value: 'MO', text: 'MO - Modena'})
            outPut.push({value: 'PC', text: 'PC - Piacenza'})
            outPut.push({value: 'PR', text: 'PR - Parma'})
            outPut.push({value: 'RA', text: 'RA - Ravenna'})
            outPut.push({value: 'RE', text: 'RE - Reggio Emilia'})
            outPut.push({value: 'RN', text: 'RN - Rimini'})
        } else if (state === 'friuli-venezia giulia') {
            outPut.push({value: 'GO', text: 'GO - Gorizia'})
            outPut.push({value: 'PN', text: 'PN - Pordenone'})
            outPut.push({value: 'TS', text: 'TS - Trieste'})
            outPut.push({value: 'UD', text: 'UD - Udine'})
        } else if (state === 'lazio') {
            outPut.push({value: 'FR', text: 'FR - Frosinone'})
            outPut.push({value: 'LT', text: 'LT - Latina'})
            outPut.push({value: 'RI', text: 'RI - Rieti'})
            outPut.push({value: 'RM', text: 'RM - Roma'})
            outPut.push({value: 'VT', text: 'VT - Viterbo'})
        } else if (state === 'liguria') {
            outPut.push({value: 'GE', text: 'GE - Genova'})
            outPut.push({value: 'IM', text: 'IM - Imperia'})
            outPut.push({value: 'SP', text: 'SP - La Spezia'})
            outPut.push({value: 'SV', text: 'SV - Savona'})
        } else if (state === 'lombardia') {
            outPut.push({value: 'BG', text: 'BG - Bergamo'})
            outPut.push({value: 'BS', text: 'BS - Brescia'})
            outPut.push({value: 'CO', text: 'CO - Como'})
            outPut.push({value: 'CR', text: 'CR - Cremona'})
            outPut.push({value: 'LC', text: 'LC - Lecco'})
            outPut.push({value: 'LO', text: 'LO - Lodi'})
            outPut.push({value: 'MN', text: 'MN - Mantova'})
            outPut.push({value: 'MI', text: 'MI - Milano'})
            outPut.push({value: 'PV', text: 'PV - Pavia'})
            outPut.push({value: 'SO', text: 'SO - Sondrio'})
            outPut.push({value: 'VA', text: 'VA - Varese'})
        } else if (state === 'marche') {
            outPut.push({value: 'AN', text: 'AN - Ancona'})
            outPut.push({value: 'AP', text: 'AP - Ascoli Piceno'})
            outPut.push({value: 'FM', text: 'FM - Fermo'})
            outPut.push({value: 'MC', text: 'MC - Macerata'})
            outPut.push({value: 'PU', text: 'PU - Pesaro'})
        } else if (state === 'molise') {
            outPut.push({value: 'CB', text: 'CB - Campobasso'})
            outPut.push({value: 'IS', text: 'IS - Isernia'})
        } else if (state === 'piemonte') {
            outPut.push({value: 'AL', text: 'AL - Alessandria'})
            outPut.push({value: 'AT', text: 'AT - Asti'})
            outPut.push({value: 'BI', text: 'BI - Biella'})
            outPut.push({value: 'CN', text: 'CN - Cuneo'})
            outPut.push({value: 'NO', text: 'NO - Novara'})
            outPut.push({value: 'TO', text: 'TO - Torino'})
            outPut.push({value: 'VB', text: 'VB - Verbania'})
            outPut.push({value: 'VC', text: 'VC - Vercelli'})
        } else if (state === 'puglia') {
            outPut.push({value: 'BA', text: 'BA - Bari'})
            outPut.push({value: 'BT', text: 'BT - Barletta-Andria-Trani'})
            outPut.push({value: 'BR', text: 'BR - Brindisi'})
            outPut.push({value: 'FG', text: 'FG - Foggia'})
            outPut.push({value: 'LE', text: 'LE - Lecce'})
            outPut.push({value: 'TA', text: 'TA - Taranto'})
        } else if (state === 'sardegna') {
            outPut.push({value: 'CA', text: 'CA - Cagliari'})
            outPut.push({value: 'VS', text: 'VS - Medio Campidano'})
            outPut.push({value: 'NU', text: 'NU - Nuoro'})
            outPut.push({value: 'OR', text: 'OR - Oristano'})
            outPut.push({value: 'SS', text: 'SS - Sassari'})
            outPut.push({value: 'SU', text: 'SU - Sud Sardegna'})
        } else if (state === 'sicilia') {
            outPut.push({value: 'AG', text: 'AG - Agrigento'})
            outPut.push({value: 'CL', text: 'CL - Caltanissetta'})
            outPut.push({value: 'CT', text: 'CT - Catania'})
            outPut.push({value: 'EN', text: 'EN - Enna'})
            outPut.push({value: 'ME', text: 'ME - Messina'})
            outPut.push({value: 'PA', text: 'PA - Palermo'})
            outPut.push({value: 'RG', text: 'RG - Ragusa'})
            outPut.push({value: 'SR', text: 'SR - Siracusa'})
            outPut.push({value: 'TP', text: 'TP - Trapani'})
        } else if (state === 'toscana') {
            outPut.push({value: 'AR', text: 'AR - Arezzo'})
            outPut.push({value: 'FI', text: 'FI - Firenze'})
            outPut.push({value: 'GR', text: 'GR - Grosetto'})
            outPut.push({value: 'LI', text: 'LI - Livorno'})
            outPut.push({value: 'LU', text: 'LU - Lucca'})
            outPut.push({value: 'MS', text: 'MS - Massa'})
            outPut.push({value: 'PI', text: 'PI - Pisa'})
            outPut.push({value: 'PT', text: 'PT - Pistoia'})
            outPut.push({value: 'PO', text: 'PO - Prato'})
            outPut.push({value: 'SI', text: 'SI - Siena'})
        } else if (state === 'trentino-alto adige') {
            outPut.push({value: 'BZ', text: 'BZ - Bolzano'})
            outPut.push({value: 'TN', text: 'TN - Trento'})
        } else if (state === 'umbria') {
            outPut.push({value: 'PG', text: 'PG - Perugia'})
            outPut.push({value: 'TR', text: 'TR - Termi'})
        } else if (state === 'valle d\'aosta') {
            outPut.push({value: 'AO', text: 'AO - Aosta'})
        } else if (state === 'veneto') {
            outPut.push({value: 'BL', text: 'BL - Belluno'})
            outPut.push({value: 'PD', text: 'PD - Padova'})
            outPut.push({value: 'RO', text: 'RO - Rovigo'})
            outPut.push({value: 'TV', text: 'TV - Treviso'})
            outPut.push({value: 'VE', text: 'VE - Venezia'})
            outPut.push({value: 'VI', text: 'VI - Vicenza'})
            outPut.push({value: 'VR', text: 'VR - Verona'})
        }
        return outPut
    },
    idCardTypes() {
        return [
            {value: '', text: i18n.t('select')},
            {value: 'PASS', text: i18n.t('passport')},
            {value: 'ID', text: 'ID'},
            {value: 'AUTO', text: i18n.t('drivingLicense')}
        ]
    },
    mealPlans() {
        return [
            {value: '14', text: i18n.t('mealPlan.roomOnly')},
            {value: '19', text: i18n.t('mealPlan.breakfast')},
            {value: '12', text: i18n.t('mealPlan.halfBoard')},
            {value: '10', text: i18n.t('mealPlan.fullBoard')}
        ]
    },
    rateAges() {
        return [
            {value: '0', text: i18n.tc('agesCount', 0)},
            {value: '1', text: i18n.tc('agesCount', 1)},
            {value: '2', text: i18n.tc('agesCount', 2)},
            {value: '3', text: i18n.tc('agesCount', 3)},
            {value: '4', text: i18n.tc('agesCount', 4)},
            {value: '5', text: i18n.tc('agesCount', 5)},
            {value: '6', text: i18n.tc('agesCount', 6)},
            {value: '7', text: i18n.tc('agesCount', 7)},
            {value: '8', text: i18n.tc('agesCount', 8)},
            {value: '9', text: i18n.tc('agesCount', 9)},
            {value: '10', text: i18n.tc('agesCount', 10)},
            {value: '11', text: i18n.tc('agesCount', 11)},
            {value: '12', text: i18n.tc('agesCount', 12)},
            {value: '13', text: i18n.tc('agesCount', 13)},
            {value: '14', text: i18n.tc('agesCount', 14)},
            {value: '15', text: i18n.tc('agesCount', 15)},
            {value: '16', text: i18n.tc('agesCount', 16)},
            {value: '17', text: i18n.tc('agesCount', 17)}
        ]
    },
    textEditorOptions() {
        return {
            fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
            placeholder: '',
            tabsize: 2,
            height: 300,
            dialogsInBody: true,
            disableDragAndDrop: true,
            shortcuts: false,
            tabDisable: true,
            toolbar: [
                ['style', ['style']],
                ['font', ['fontname', 'bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link']]
            ]
        }
    },
    getCountryISO2(threeCode) {
        const getCountryISO2 = require("./countries");
        return getCountryISO2(threeCode);
    },
    getCountriesDialCodes() {
        const getCountriesDialCodes = require("./countries");
        return getCountriesDialCodes();
    },
    showShortFileName(fileName) {
        const length = fileName.length
        if (length < 10) {
            return fileName
        } else {
            const split = fileName.split('.')
            if (split.length > 1) {
                const extension = fileName.split('.').pop().toLowerCase()
                split.pop()
                const newName = split.join()
                return `${newName.substring(0, 5)}..${extension}`
            } else {
                return fileName.substring(0, 5) + '...'
            }
        }
    },
    checkHouseOnBoardStep(requireId, currentId) {
        const pageId = parseInt(requireId)
        const dbId = parseInt(currentId)
        if (dbId >= pageId) {
            return {
                error: false
            }
        } else {
            let route = 'houseOnBoardContact'
            if (dbId === 3) {
                route = 'houseOnBoardAmenities'
            } else if (dbId === 4) {
                route = 'houseOnBoardRules'
            } else if (dbId === 5) {
                route = 'houseOnBoardImages'
            } else if (dbId === 6) {
                route = 'houseOnBoardParking'
            }
            return {
                error: true,
                route: route
            }
        }
    },
    getCustomerDefaultParam() {
        return {
            id: '0',
            title: '0',
            company: '',
            firstName: '',
            lastName: '',
            email: '',
            dialCode: '',
            contactNumber: '',
            street: '',
            city: '',
            postalCode: '',
            country: '',
            state: '',
            province: '',
            uid: '',
            accountNumber: '',
            taxReceiverCode: '',
            type: '1',
            commission: '0',
            dob: '0',
        }
    },
    getTravellerDefaultParam() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            dob: '',
            age: 0,
            price: 0,
            gender: '1',
            type: '1',
            birthPlace: '',
            street: '',
            postalCode: '',
            city: '',
            birthCountry: '',
            country: '',
            state: '',
            province: '',
            documentNumber: '',
            documentType: '',
            documentIssuedPlace: '',
            passportFile: ''
        }
    },
    restaurantDepartments() {
        return [
            {value: 'BAR', text: 'Bar'},
            {value: 'KITCHEN', text: 'Kitchen'},
        ]
    },
    showCancelSubscriptionDialog() {
        Vue.swal({
            icon: 'info',
            title: i18n.t('information'),
            text: i18n.t('cancelSubscriptionsText'),
            showConfirmButton: true,
            keydownListenerCapture: false,
            heightAuto: false,
            showCancelButton: false,
            confirmButtonText: 'Okay'
        })
    },
    integrationRateShow(priceObject) {
        const billPeriod = priceObject.billPeriod
        const price = this.convertToEu(priceObject.rate)
        if (billPeriod === 'YEARLY') {
            return i18n.t('pricePerYear', {price: price})
        } else if (billPeriod === 'ONE_TIME') {
            return `${price} €`
        } else {
            return i18n.t('pricePerMonth', {price: price})
        }
    }
}
export default helper
